/**
 * @file Main file, starts all the initializations
*/

// NE METTRE QUE LES FONCTIONS TROP PETITES POUR MÉRITER UN FICHIER À PART ET QUI SERONT UTILISÉES SUR TOUTES LES PAGES

// $env:MERCURE_PUBLISHER_JWT_KEY='!ChangeMe!'; $env:MERCURE_SUBSCRIBER_JWT_KEY='!ChangeMe!'; .\mercure.exe run -config Caddyfile.dev

// The subscriber subscribes to updates for the https://example.com/users/dunglas topic
// and to any topic matching https://example.com/books/{id}
// const url = new URL('https://localhost/.well-known/mercure');
// The URL class is a convenient way to generate URLs such as https://localhost/.well-known/mercure?topic=https://example.com/books/{id}&topic=https://example.com/users/dunglas

// var eventSource = null;

const removePageLoader = function(_page = null) {
    _log('removePageLoader');
    if(_page == null) {removePageLoader(current_page ?? "any")}
    else if(_page == "any") {}
    else if(_page == "home") {}
    else if(_page == "annonces") {}
    else if(_page == "annonce") {}
    let elem = document.getElementById('page-loader');
    elem && elem.remove();
}

document.addEventListener('DOMContentLoaded', function() {

    typeof initUsedIDs == "function" && initUsedIDs();

    // DONE Fixed unknown function
    // typeof initVCalendars == "function" && initVCalendars();
    typeof initPopins == "function" && initPopins();
    typeof initTUICalendars == "function" && initTUICalendars();
    // typeof initManageColumns == "function" && initManageColumns();
    typeof initFilters == "function" && initFilters();
    typeof initInputSearch == "function" && initInputSearch();
    typeof initNavRight == "function" && initNavRight();
    typeof initPagination == "function" && initPagination();
    typeof initDragula == "function" && initDragula();

    switch(current_page) {
        // Accueil
        case "home":
            typeof __home__Init == "function" && __home__Init();
            break;
        default:
            break;
    }
    
    // typeof __table_functions__Init == "function" && __table_functions__Init();
    typeof initInputMails == "function" && initInputMails();
    typeof initInputDates == "function" && initInputDates();
    typeof initSelectNone == "function" && initSelectNone();
    typeof initAccordions == "function" && initAccordions();
    typeof initInputImage == "function" && initInputImage();
    typeof initSelect == "function" && initSelect();

    window.addEventListener('popstate', (e) => {
        console.log('popstate');
        let click = this.createElement('a');
        click.href = window.location.pathname;
        click.click();
    });

    // MERCURE
    // eventSource = new EventSource(url);
    // The callback will be called every time an update is published
    // eventSource.onmessage = e => console.log(e); // do something with the payload

    removePageLoader(current_page);
});
